<template>
  <div v-show="deliverables.length > 0">
    <a
      :class="{
        error: deliverables.length > 0,
        blue: deliverables.length === 0,
      }"
      class="ui icon label circular margin-left-zero"
      @click="openModal"
    >
      <i
        class="icon"
        :class="{
          'exclamation triangle': deliverables.length > 0,
          'blue check circle': deliverables.length === 0,
        }"
      ></i>
      {{ deliverables.length }} Issue{{ deliverables.length === 1 ? "" : "s" }}
    </a>

    <div class="ui modal" ref="modal">
      <i class="icon close"></i>
      <div class="ui header">
        Assistant
        <div class="sub header">
          {{ deliverables.length | pluralize("Issue") }}
        </div>
      </div>
      <div class="content">
        <div
          class="ui yellow message"
          v-if="!hideDisconnectedAccounts && requiredProviders.size > 0"
        >
          <div class="ui center aligned small header">
            <strong>Connect these acccounts</strong>
          </div>

          <div class="ui list">
            <div
              v-for="provider in requiredProviders"
              class="item"
              :key="provider"
            >
              <a
                :href="`/authorize/${provider}?origin=${currentUrl}`"
                class="ui fluid white button bordered"
                data-turbo="false"
              >
                <i class="large icon colored" :class="provider"></i>
                <strong>Connect {{ provider | capitalize }}</strong>
              </a>
            </div>
          </div>
        </div>

        <div class="ui segment" v-show="deliverables.length < 1">
          No deliverables.
        </div>

        <div
          class="ui info message"
          v-if="neverAttempted.length == nonIgStoryDeliverables.length"
        >
          We're still fetching stats for these deliverables. Please check back
          soon.
        </div>

        <div v-show="deliverables.length > 0">
          <div
            v-if="!onlyErrored && igStoryDeliverables.length > 0"
            class="ui segment"
          >
            <i class="icon grey large instagram_stories"></i>
            <div class="content">
              Instagram Stories Frames ({{ igStoryDeliverables.length }})
            </div>
          </div>

          <div
            v-for="deliverable in deliverables"
            :key="deliverable.id"
            class="ui segment"
            :class="{
              errored: deliverable.reporting_status.status != 'ok',
            }"
          >
            <div
              class="ui top attached header"
              :style="{
                backgroundColor:
                  colors[inferredTypeAsIconClass(deliverable.inferred_type)] ||
                  '#cccccc',
              }"
            >
              <i
                :class="[deliverable.reporting_status.icon]"
                class="icon link right floated"
                @click.alt="openReportingStatus(deliverable, $event)"
                @click.exact="refreshStats(deliverable, $event)"
              />

              <i
                class="icon left floated"
                :class="inferredTypeAsIconClass(deliverable.inferred_type)"
              />
              {{ inferredTypeAsLabel(deliverable.inferred_type) | toCamelCase }}
            </div>

            <div class="content">
              <p class="ui message clearing">
                <span
                  v-html="statusMessage(deliverable)"
                  class="margin-bottom-small"
                ></span>

                <br />
                <br />
                <turbo-frame
                  :id="`ai-help-message-${deliverable.id}`"
                  :src="`/deliverables/${deliverable.id}/ai_help_message`"
                  loading="lazy"
                >
                  <i class="ui spinner loading icon"></i>
                </turbo-frame>

                <a
                  v-if="
                    deliverable.last_attempted_at &&
                    !deliverable.last_fetched &&
                    !deliverable.reporting_status.status == 'disconnected'
                  "
                  href="#"
                  @click.stop.prevent="refreshStats(deliverable, $event)"
                  class="right floated"
                >
                  <i class="icon sync"></i>
                  Try refreshing
                </a>
                <br />
                <span class="ui grey text deliverableLabel">
                  {{ deliverableLabel(deliverable) }}
                  <a
                    v-if="hasUrl(deliverable)"
                    :href="deliverable.url"
                    target="_blank"
                  >
                    <i class="icon external"></i>
                  </a>
                </span>

                <a
                  v-if="deliverable.last_error_subtype == 'unsupported'"
                  href="http://help.influencekit.com/en/articles/3468401-how-do-i-add-facebook-stories-reels-or-expired-instagram-stories-to-a-report"
                  class="ui pink tertiary button"
                  target="_blank"
                >
                  <i class="icon lightbulb"></i>
                  <strong>Here's how to add stats manually</strong>
                </a>
              </p>

              <p class="flex flex-justify-end">
                <a
                  href="mailto:help@influencekit.com"
                  class="show-intercom ui tertiary button icon flex-shrink-0"
                  :data-message="`I need help troubleshooting a deliverable: ${
                    deliverable.url
                  }\n\n(${deliverable.id}) Error: ${statusMessage(
                    deliverable
                  )}`"
                >
                  <i class="icon help"></i>
                  Get more help
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "store";
import { mapState, mapGetters } from "vuex";
import { isEmpty } from "lodash";

const { $ } = window;

export default {
  store,
  name: "Troubleshooter",
  props: {
    hideManageLink: {
      default: true,
      type: Boolean,
    },
    currentUrl: {
      type: String,
      default: window.location.href,
    },
    initialDeliverables: Array,
    hideDisconnectedAccounts: {
      type: Boolean,
      default: false,
    },
    onlyErrored: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    refreshStats(deliverable, event) {
      $(event.target).removeClass().addClass("icon loading sync right floated");
      $(event.target).find(".icon").removeClass().addClass("icon loading sync");
      $.read(`/deliverables/${deliverable.id}/refresh`);
    },
    statusMessage(deliverable) {
      return deliverable.reporting_status.message == ""
        ? "Unknown status"
        : deliverable.reporting_status.message;
    },
    deliverableLabel(deliverable) {
      return !this.hasUrl(deliverable) ? deliverable.name : deliverable.url;
    },
    hasUrl(deliverable) {
      return !isEmpty(deliverable.url);
    },
    openDeliverablesModal() {
      window
        .$(".deliverables.modal")
        .modal({
          allowMultiple: true,
        })
        .modal("show");
    },
    openReportingStatus(deliverable) {
      window.open(`/deliverables/${deliverable.id}/status`, "_blank");
    },
    refreshModal() {
      $(this.$refs.modal).modal("refresh");
    },
    openModal() {
      $(this.$refs.modal)
        .modal({
          observeChanges: true,
        })
        .modal("show");
    },
    showEditLink(deliverable) {
      if (this.hideManageLink) return false;
      if (!deliverable) return false;
      if (window.currentUserId && window.currentUserId == deliverable.user_id) {
        return true;
      } else {
        return false;
      }
    },
    initPopups() {
      $(this.$el)
        .find(".list .item.errored")
        .each(function () {
          $(this)
            .find(".icon")
            .popup({
              closable: false,
              exclusive: true,
              observeChanges: true,
              popup: $(this).find(".popup"),
              hoverable: true,
              distanceAway: 10,
              // position: "left center",
              target: $(this),
              transition: "fade",
              delay: {
                show: 100,
              },
            });
        });
    },
  },
  computed: {
    ...mapState("deliverable", ["all", "colors"]),
    ...mapGetters("deliverable", [
      "inferredTypeAsIconClass",
      "inferredTypeAsLabel",
    ]),

    igStoryDeliverables() {
      return this.all.filter((d) => d.inferred_type === "instagram_stories");
    },
    nonIgStoryDeliverables() {
      return this.all.filter((d) => d.inferred_type != "instagram_stories");
    },
    deliverables() {
      let array = this.nonIgStoryDeliverables;
      if (this.onlyErrored) {
        array = this.erroredDeliverables;
      }
      const sorted = [...array].sort((a, b) => {
        let a_last = new Date(a.last_error_at || a.last_fetched);
        let b_last = new Date(b.last_error_at || b.last_fetched);

        if (!a.last_error_at && !a.last_fetched) return -1;
        if (a.last_fetched && !a.last_error_at) return -1;

        return a_last < b_last ? -1 : 1;
      });
      return sorted;
    },
    erroredDeliverables() {
      return this.nonIgStoryDeliverables.filter(
        (d) => d.url && !d.last_fetched && !d.skip_refresh
      );
    },
    neverAttempted() {
      return this.nonIgStoryDeliverables.filter(
        (d) => d.url && !d.last_attempted_at
      );
    },
    disconnectedDeliverables() {
      return this.deliverables.filter(
        (d) =>
          !d.inferred_type.includes("_profile") &&
          d.reporting_status.status == "disconnected"
      );
    },
    requiredProviders() {
      let providers = this.disconnectedDeliverables.map((d) => d.inferred_type);

      providers = providers
        .map((x) => {
          return ["instagram", "instagram_stories"].includes(x)
            ? "facebook"
            : x;
        })
        .map((x) => (x == "url" ? "google" : x));

      providers = new Set(providers);
      providers.delete("other");
      return providers;
    },
  },
  mounted() {
    if (this.initialDeliverables) {
      this.$store.dispatch("deliverable/setAll", {
        items: this.initialDeliverables,
      });
      this.refreshModal();
    }
    this.$nextTick(function () {
      this.initPopups();
      let params = new URLSearchParams(document.location.search);

      if (params.get("showIssues") == "true") {
        if (!$(".modal").modal("is active").some(Boolean)) {
          //don't open if another modal is already open on page load
          this.openModal();
        }
      }
    });
  },
  updated() {
    this.$nextTick(function () {
      setTimeout(() => {
        this.refreshModal();
      }, 1000);

      this.initPopups();
    });
  },
};
</script>

<style scoped lang="scss">
.deliverableLabel {
  word-break: break-all;
  overflow: hidden;
  display: inline-flex;
  max-width: 90%;
  text-overflow: ellipsis;
  font-size: 0.8em;
}

.segment .ui.list .item {
  .external {
    visibility: hidden;
  }

  &:hover {
    .external {
      visibility: visible;
    }
  }
}

.ui.top.attached.header {
  text-transform: capitalize;
  color: #fff;
  margin: -1em;
  margin-bottom: 1em;
  border-width: 0;
}
</style>
